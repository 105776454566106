import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'

export default () => (
    <Layout>
        <section className="section">
            <div className="container">
                <div className="content">
                    <h1>Thank you!</h1>
                    <p>We will review your correspondance and get back with you shortly.</p>
                    <Link to="/">Go back home.</Link>
                </div>
            </div>
        </section>
    </Layout>
)
